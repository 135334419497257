import { render, staticRenderFns } from "./AccountEmailSend.vue?vue&type=template&id=11b35534&scoped=true&"
import script from "./AccountEmailSend.vue?vue&type=script&lang=js&"
export * from "./AccountEmailSend.vue?vue&type=script&lang=js&"
import style0 from "./AccountEmailSend.vue?vue&type=style&index=0&id=11b35534&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11b35534",
  null
  
)


    import installComponents from "!D:/IIS/rentmagic.website.vue.vlaams-brabant/test/node_modules/bootstrap-vue-loader/lib/runtime/installComponents.js"
    import {BCol, BRow, BContainer} from 'bootstrap-vue'
    installComponents(component, {BCol, BRow, BContainer})
    

export default component.exports